import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { FiltersWrapperProps } from './types';
import classes from './FiltersWrapper.module.scss';

export const FiltersWrapper = ({ children, title, className }: FiltersWrapperProps) => {
  return (
    <Box direction="column" className={cn(classes.content, className)}>
      {!!title && <h3 className={classes.title}>{title}</h3>}
      {children}
    </Box>
  );
};
