import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import classes from './Heading.module.scss';
import { HeadingProps } from './types';

export const Heading = ({ children, className }: HeadingProps) => (
  <Box className={classes.wrap} alignItems="center">
    <h3 className={cn(classes.content, className)}>{children}</h3>
  </Box>
);
