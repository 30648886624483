'use client';

import {
  useMemo, useCallback, useRef, useState,
} from 'react';
import { Formik, FormikProps, FormikState } from 'formik';
import { Box } from 'uikitv2/Box';
import { Cards2 } from 'uikitv2/Cards2';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import { isMobileSelector } from 'lib/features/windowSize';
import { useExtendedFormContent } from 'lib/features/createOrderV2/hooks/useExtendedFormContent';
import { useContent } from 'lib/features/createOrderV2/hooks/useContent';
import { pageFiltersSelector, viewTypeSelector } from 'lib/features/shopwindow/selectors';
import {
  Blocks, FormControlPanel, ViewType,
} from 'lib/features/shopwindow/types';
import { updatePageFilters } from 'lib/features/shopwindow';
import { getPreparedForm } from 'lib/features/shopwindow/helpers';
import { useOnClickRow } from 'hooks/useOnClickRow';
import { ControlPanel } from './ControlPanel';
import { Filters } from './Filters';
import {
  getValidationSchema, getColumns, getFields, INTERVAL,
} from './helpers';
import useOffers from './hooks/useOffers';
import classes from './Content.module.scss';
import { Form, ContentProps, FormFilters } from './types';
import Table from './Table/Table';
import { Heading } from './Heading';

export const Content = ({ active, prefetch }: ContentProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const previousFormRef = useRef<string>('');
  const dispatch = useAppDispatch();
  const filters = useAppSelector(pageFiltersSelector(active));
  const { addOfferWithCheckMultipleSlotsCatched, deleteContent, loading: loadingUpdateContent } = useContent();
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { loading: loadingExtending, extendedFormContent } = useExtendedFormContent(buildOrderForm);
  const viewType = useAppSelector(viewTypeSelector(active));
  const [isValidating, setIsValidating] = useState(false);
  const { onClickRow } = useOnClickRow();

  const {
    data, refetch, fetchNextPage: fetchNextPageOffers, isFetchingNextPage, pageInfo, pageData,
  } = useOffers(prefetch?.data);

  const handleSubmitForm = useCallback((formikProps: FormikProps<Form>) => async (newValues?: Form | null) => {
    const { submitForm, values, resetForm } = formikProps || {};
    const newFilters = newValues || values;
    // prevent refetch if equal filters
    if (previousFormRef.current === JSON.stringify(newFilters)) {
      return;
    }
    previousFormRef.current = JSON.stringify(newFilters);
    if (newValues) {
      resetForm(newValues as Partial<FormikState<Form>>);
    }
    dispatch(updatePageFilters({ page: active, filters: newFilters }));
    // execute after main thread completed
    setTimeout(() => {
      submitForm();
      setIsValidating(true);
    }, 1);
  }, [active, dispatch]);

  const handleSubmitFilters = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormFilters) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.filters]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const handleSubmitControlPanel = useCallback((formikProps: FormikProps<Form>) => async (newValues?: FormControlPanel) => {
    const { values: oldValues } = formikProps || {};
    handleSubmitForm(formikProps)(newValues ? { ...oldValues, [Blocks.controlPanel]: newValues } : oldValues);
  }, [handleSubmitForm]);

  const onSubmitForm = useCallback((form: Form) => {
    refetch(getPreparedForm(active, form, extendedFormContent));
  }, [refetch, extendedFormContent, active]);

  const fetchNextPage = useCallback(({ values }) => () => {
    fetchNextPageOffers(getPreparedForm(active, values, extendedFormContent));
  }, [fetchNextPageOffers, extendedFormContent, active]);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const columns = useMemo(
    () => getColumns({
      onDeleteContent: deleteContent,
      onAddContent: addOfferWithCheckMultipleSlotsCatched,
      extendedFormContent,
      loading: loadingUpdateContent || loadingExtending,
      isMobile,
      active,
    }),
    [
      addOfferWithCheckMultipleSlotsCatched,
      deleteContent,
      extendedFormContent,
      loadingUpdateContent,
      loadingExtending,
      isMobile,
      active,
    ],
  );

  const fields = useMemo(() => (
    getFields({
      onAddContent: addOfferWithCheckMultipleSlotsCatched,
      onDeleteContent: deleteContent,
      extendedFormContent,
      loading: loadingUpdateContent || loadingExtending,
      active,
    })
  ), [loadingUpdateContent, extendedFormContent, loadingExtending, addOfferWithCheckMultipleSlotsCatched, deleteContent, active]);

  const classNames = useMemo(() => ({
    tr: classes.tr,
  }), []);

  const getRowId = useCallback((data) => {
    const { original } = data;
    return original?.id || original?.teeOffer?.id;
  }, []);

  const isShowCards = viewType === ViewType.cards || isMobile;

  return (
    <Formik
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
      initialValues={filters}
      onSubmit={onSubmitForm}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <Box>
            <Box direction="column">
              <Heading>{active}</Heading>
              <Filters
                className={classes.filters}
                name={Blocks.filters}
                active={active}
                onSubmit={handleSubmitFilters(formikProps)}
                interval={INTERVAL}
              />
            </Box>
            <Box direction="column" className={classes.content}>
              <ControlPanel
                name={Blocks.controlPanel}
                columns={columns}
                active={active}
                onSubmit={handleSubmitControlPanel(formikProps)}
                showCount={data?.length}
                totalCount={pageData?.count}
                interval={INTERVAL}
              />
              {isShowCards
                ? (
                  <Cards2
                    fields={fields}
                    data={data}
                    onClickCard={onClickRow}
                    fetchNextPage={fetchNextPage(formikProps)}
                    hasNextPage={pageInfo?.hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                ) : (
                  <Table
                    columns={columns}
                    active={active}
                    data={data}
                    fetchNextPage={fetchNextPage(formikProps)}
                    hasNextPage={pageInfo?.hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    onClickRow={onClickRow}
                    classNames={classNames}
                    getRowId={getRowId}
                    classNameContainer={classes['table-container']}
                  />
                )}
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
