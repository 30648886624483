import * as Types from '../types';

import { api } from '../../connectors/sp';
export type GetUsedPipelineTypesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OfferFilter>;
}>;


export type GetUsedPipelineTypesQuery = { __typename?: 'Query', result: Array<Types.PipelineType> };


export const GetUsedPipelineTypesDocument = `
    query GetUsedPipelineTypes($filter: OfferFilter) {
  result: getUsedPipelineTypes(filter: $filter)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetUsedPipelineTypes: build.query<GetUsedPipelineTypesQuery, GetUsedPipelineTypesQueryVariables | void>({
      query: (variables) => ({ document: GetUsedPipelineTypesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUsedPipelineTypesQuery, useLazyGetUsedPipelineTypesQuery } = injectedRtkApi;

