import { createSelector } from '@reduxjs/toolkit';
import { filtersSelector } from '.';
import { Pages } from './types';

export const pageFiltersSelector = (page: Pages) => createSelector(
  filtersSelector,
  (pages) => pages[page],
);

export const viewTypeSelector = (page: Pages) => createSelector(
  filtersSelector,
  (pages) => pages[page]?.controlPanel?.view,
);
