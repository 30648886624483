import { memo, useMemo } from 'react';

import { Icon } from 'uikitv2/Icon';
import { SquareButton } from 'uikitv2/Buttons/SquareButton';
import { SwitcherProps } from './types';
import { getSwitcherSettings } from './helpers';

export const Switcher = memo<SwitcherProps>(({ className, onClick, viewType }) => {
  const switcherSettings = useMemo(() => getSwitcherSettings(viewType), [viewType]);
  return (
    <SquareButton
      variant="obscure"
      size="large"
      className={className}
      onClick={onClick}
      data-testid="button-switchmode"
    >
      <Icon {...switcherSettings} />
    </SquareButton>
  );
});

export default Switcher;
