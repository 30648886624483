import {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  ConnectionArgs,
} from 'generated/types';

export interface Variables {
  pagination: ConnectionArgs;
  filter: Record<string, any>;
}

export type PageInfo = {
  endCursor?: string | null,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  startCursor?: string | null
} | null;

export type PageData = {
  count?: number,
  limit?: number,
  offset?: number
} | null | undefined;

export type ResultType<TNode> = {
  result?: {
    pageData: PageData,
    page?: {
      pageInfo?: PageInfo,
      edges?: Array<{ cursor?: string | null, node?: TNode }>
    }
  }
};

export type FetchResult<TNode> = {
  data: TNode[];
  fetchNextPage: (variables?: Variables) => void;
  pageInfo?: PageInfo;
  pageData?: PageData;
  isFetchingNextPage: boolean;
  refetch: Function;
  isLoading: boolean;
}

interface UseLazyPaginationProps<TNode, ReducerPath extends string = string> {
  // eslint-disable-next-line max-len
  // useLazyQuery: UseLazyQuery<QueryDefinition<QueryConfigsArgs, any, any, ResultType<Node, ReducerPath>>, // todo ResultType<Node>
  useLazyQuery: UseLazyQuery<QueryDefinition<any, any, any, any, ReducerPath>>;
  nextPage?: number | null;
  prefetch?: ResultType<TNode> | null;
}

function useLazyPagination<TNode, ReducerPath extends string = string>({
  useLazyQuery,
  nextPage = 10,
  prefetch,
}: UseLazyPaginationProps<TNode, ReducerPath>): FetchResult<TNode> {
  const [data, setData] = useState<TNode[]>(
    prefetch ? (prefetch?.result?.page?.edges || []).map(({ node }) => node as TNode) : [],
  );
  const [refetch, response] = useLazyQuery();
  const result = useMemo(
    () => response?.data?.result || prefetch?.result,
    [response, prefetch?.result],
  );
  const pageInfo = useMemo(() => result?.page?.pageInfo, [result]);
  const pageData = useMemo(() => result?.pageData, [result]);

  const fetchNextPage = useCallback((variables?: Variables) => {
    refetch({
      ...variables,
      pagination: {
        after: pageInfo?.endCursor,
        first: nextPage,
        ...variables?.pagination,
      },
      filter: { ...variables?.filter },
    });
  }, [refetch, pageInfo, nextPage]);

  const refetchList = useCallback((variables?: Variables, preferCacheValue?: boolean) => {
    setData([]);
    return refetch({
      ...variables,
      pagination: {
        first: nextPage,
        ...variables?.pagination,
      },
      filter: { ...variables?.filter },
    }, preferCacheValue);
  }, [refetch, nextPage]);

  useEffect(() => {
    const fetched = response ? response?.data?.result?.page?.edges?.map(({ node }) => node as TNode) : [];
    if (fetched && !response.isFetching) {
      setData((prev) => (fetched ? prev.concat(fetched) : prev));
    }
  }, [response]);

  return {
    data,
    fetchNextPage,
    pageInfo,
    pageData,
    isFetchingNextPage: response.isFetching,
    refetch: refetchList,
    isLoading: response.isLoading,
  };
}

export default useLazyPagination;
