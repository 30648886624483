import * as Yup from 'yup';
import { ColumnDef } from '@tanstack/react-table';
import { TOfferType } from 'generated/types';
import { FieldsBuildOrderForm } from 'lib/features/createOrderV2/types';
import {
  FiltersFields, Blocks, FormControlPanel, FormFiltersDatasets, FormFiltersModels, Pages, ControlPanelFields, ViewType, Sorting,
} from 'lib/features/shopwindow/types';
import { GetColumnsProps, Form } from './types';
import { getColumns as getColumnsDatasets } from './columns/datasets';
import { getFields as getFieldsDatasets } from './fields/datasets';
import { GetFieldsProps } from './fields/types';
import { Column } from './Table/types';

export const INTERVAL = 300;

export const getValidationSchemaConrolPanel = (): Yup.SchemaOf<FormControlPanel> => Yup.object({
  [ControlPanelFields.sorting]: Yup.mixed().oneOf(Object.values(Sorting)).nullable().notRequired(),
  [ControlPanelFields.size]: Yup.number().nullable().notRequired(),
  [ControlPanelFields.view]: Yup.mixed<ViewType>().oneOf(Object.values(ViewType)).nullable().notRequired(),
});

export const getValidationSchemaFilters = (): Yup.SchemaOf<FormFiltersDatasets | FormFiltersModels> => Yup.object({
  [FiltersFields.compatible]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
  [FiltersFields.categories]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
  [FiltersFields.offerType]: Yup.mixed<TOfferType>().oneOf(Object.values(TOfferType)).notRequired(),
  [FiltersFields.offers]: Yup.array().of(Yup.string().required()).notRequired().nullable(),
});

export const getValidationSchema = (): Yup.SchemaOf<Form> => Yup.object({
  [Blocks.filters]: getValidationSchemaFilters().nullable().notRequired(),
  [Blocks.controlPanel]: getValidationSchemaConrolPanel().nullable().notRequired(),
});

const fieldsMap = {
  [Pages.datasets]: (props: GetFieldsProps) => (
    getFieldsDatasets({ ...props, field: FieldsBuildOrderForm.datasets })
  ),
  [Pages.models]: (props: GetFieldsProps) => (
    getFieldsDatasets({ ...props, field: FieldsBuildOrderForm.model })
  ),
};

export const getFields = ({
  loading, onAddContent, extendedFormContent, onDeleteContent, active,
}) => {
  return fieldsMap?.[active]?.({
    loading, onAddContent, extendedFormContent, onDeleteContent,
  }) || null;
};

export const getBaseOffers = (data): string[] => {
  const ids: string[] = data.map((item) => (item?.offerInfo?.restrictions?.offers || [])).flat();
  return [...new Set(ids)];
};

const columnsMap = {
  [Pages.datasets]: (props) => (
    getColumnsDatasets({ ...props, field: FieldsBuildOrderForm.datasets })
  ),
  [Pages.models]: (props) => (
    getColumnsDatasets({ ...props, field: FieldsBuildOrderForm.model })
  ),
};

export function getColumns({
  ...rest
}: GetColumnsProps): ColumnDef<Column>[] {
  return columnsMap?.[rest?.active]?.(rest);
}
