import { memo } from 'react';

import { useAppSelector } from 'lib/hooks';
import { Table as TableUikit } from 'uikitv2/Table';
import { columnsTableSelector } from 'lib/features/tableSettings/selectors';
import useTableSettings from 'hooks/useTableSettings';
import { TableProps, Column } from './types';

const Table = memo(({ active, columns: columnsProp, ...props }: TableProps) => {
  const storeColumns = useAppSelector(columnsTableSelector(active));
  const columns = useTableSettings<Column>(columnsProp, storeColumns);
  return (
    <TableUikit
      columns={columns as any}
      {...props}
    />
  );
});

export default Table;
