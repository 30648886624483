import { forwardRef, memo } from 'react';
import cn from 'classnames';

import { Paper } from 'uikitv2/Paper';
import { CardProps } from './types';
import classes from './Card2.module.scss';

export const Card2 = memo(forwardRef<HTMLDivElement, CardProps>(({
  className,
  children,
  onClick,
  testId,
}, ref) => {
  return (
    <Paper ref={ref} className={cn(classes.content, className)} onClick={onClick} testId={testId}>
      {children}
    </Paper>
  );
}));
