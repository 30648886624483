import { memo, useMemo, useCallback } from 'react';

import { LazyLoad } from 'uikitv2/LazyLoad';
import { CardTemplateData } from '../Card2/CardTemplateData';
import { getCardFields } from './helpers';
import classes from './Cards2.module.scss';
import { CardsProps } from './types';

export const Cards2 = memo(({
  fields,
  data,
  onClickCard,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: CardsProps) => {
  const Template = useMemo(() => {
    return CardTemplateData;
  }, []);
  const onClick = useCallback((rowId, row) => {
    onClickCard(rowId, row);
  }, [onClickCard]);

  return (
    <LazyLoad
      className={classes.content}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      loading={isFetchingNextPage}
      showLoader
    >
      {data.map((row) => {
        const card = getCardFields(row, fields);
        return (
          <Template
            testId={row.id}
            key={row.id}
            card={card}
            onClick={() => onClick(row.id, row)}
          />
        );
      })}
    </LazyLoad>
  );
});

export default Cards2;
