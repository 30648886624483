import dynamic from 'next/dynamic';
import { checkAddContent } from 'lib/features/createOrderV2/helpers';
import { GetFieldsProps } from './types';
import classes from '../Content.module.scss';

const AdderBtnOffer = dynamic(() => import('../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

const HtmlBox = dynamic(() => import('../../../../uikitv2/HtmlBox/HtmlBox'), { ssr: false });

export const getFields = ({
  loading, field, onAddContent, extendedFormContent, onDeleteContent,
}: GetFieldsProps) => [
  {
    id: 'id',
    cell: (row) => {
      const { id } = row || {};
      return id;
    },
  },
  {
    id: 'name',
    cell: (row) => {
      const { offerInfo } = row || {};
      const { name } = offerInfo || {};
      return name;
    },
  },
  {
    id: 'provider',
    cell: (row) => {
      const { providerInfo } = row || {};
      const { name } = providerInfo || {};
      return name;
    },
  },
  {
    id: 'description',
    cell: (row) => {
      const { offerInfo } = row || {};
      const { description } = offerInfo || {};
      return description ? (
        <HtmlBox text={description} className={classes.description} ellipsis={false} />
      ) : '';
    },
  },
  {
    id: 'button',
    cell: (row) => {
      const { id, enabled, inactive } = row || {};
      const isAdded = checkAddContent({ offerId: id, formContent: extendedFormContent, field });
      return (
        <AdderBtnOffer
          disabled={loading}
          onAdd={() => onAddContent({ value: id, data: row, field })}
          isAdded={isAdded}
          className={classes.adderBtn}
          onDelete={() => onDeleteContent({ offerId: id, field })}
          dataTestId={`offer-${id}`}
        />
      );
    },
  },
  {
    id: 'disabled',
    cell: (row) => {
      const { id, enabled } = row || {};
      // return isOfferDisabled(id, enabled);
    },
  },
];
