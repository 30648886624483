import {
  useCallback, useMemo, memo, FC,
} from 'react';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { Dropdown } from 'uikitv2/Dropdown';
import { getItems } from './helpers';
import classes from './Sorting.module.scss';
import { SortingProps } from './types';

export const Sorting: FC<SortingProps> = memo(({
  name, onSubmit, className, interval = 100, page,
}) => {
  const [, { value }, { setValue }] = useField(name);
  const items = useMemo(() => getItems(page), [page]);
  const debouncedSubmit = useDebouncedCallback(onSubmit, interval);
  const onSelect = useCallback((newValue) => {
    setValue(newValue);
    debouncedSubmit();
  }, [debouncedSubmit, setValue]);
  return (
    <Dropdown
      items={items}
      active={value}
      onSelect={onSelect}
      className={className}
      dataTestId="dropdown-sorting"
      classNameDropdownToggle={classes['dropdown-toggle']}
      classNameDropdownMenu={classes['dropdown-menu']}
      classNameMenuItem={classes['dropdown-menu-item']}
    />
  );
});
