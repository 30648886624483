import { memo, FC, useMemo } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { Box } from 'uikitv2/Box';
import { FiltersProps } from './types';
import { controls } from './helpers';
import classes from './Filters.module.scss';

export const Filters: FC<FiltersProps> = memo(({
  className, name, onSubmit, interval = 100, active,
}) => {
  const submitDebounced = useDebouncedCallback(onSubmit, interval);
  const Control = useMemo(() => controls[active], [active]);

  return (
    <Box className={cn(classes.wrap, className)} direction="column">
      {!!Control && (
        <Control {...{ name, onSubmit: submitDebounced }} />
      )}
    </Box>
  );
});