import { memo } from 'react';

import { RadioFormik } from 'uikitv2/Radio/RadioFormik';
import { CategoriesProps } from './types';
import { FiltersWrapper } from '../FiltersWrapper';
import classes from './Categories.module.scss';

export const Categories = memo(({
  name, title, onChange, list,
}: CategoriesProps) => {
  return (
    <FiltersWrapper title={title}>
      {list.map(({ value, label }) => (
        <RadioFormik
          key={value}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          classNameLabel={classes.label}
        />
      ))}
    </FiltersWrapper>
  );
});
