import { memo, FC, useCallback } from 'react';
import { FiltersFields } from 'lib/features/shopwindow/types';
// import { Offers } from '../common/Offers';
// import { Compatible } from '../common/Compatible';
import { FiltersDatasetsProps } from './types';
import { Categories } from './Categories';

export const FiltersDatasets: FC<FiltersDatasetsProps> = memo(({
  name, onSubmit: onSubmitProps,
}) => {
  const onSubmit = useCallback(() => onSubmitProps(), [onSubmitProps]);
  return (
    <>
      {/* <Compatible name={`${name}.${FiltersFields.compatible}`} title={FiltersFields.compatible} onChange={onSubmit} /> */}
      <Categories
        rootName={name}
        name={`${name}.${FiltersFields.categories}`}
        title={FiltersFields.categories}
        onChange={onSubmit}
      />
      {/* <Offers name={`${name}.${FiltersFields.offers}`} title="Offers" onChange={onSubmit} canShowBorder={false} /> */}
    </>
  );
});
