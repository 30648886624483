import { ViewType } from 'lib/features/shopwindow/types';

export const getSwitcherSettings = (viewType: ViewType): { name: any, width: number } => {
  const viewTypeMap = {
    table: {
      name: 'app-4',
      width: 10,
    },
    cards: {
      name: 'menu',
      width: 12,
    },
  };
  return viewTypeMap[viewType];
};
