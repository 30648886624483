import { memo, FC, useMemo } from 'react';
import { useField } from 'formik';

import { useGetUsedPipelineTypesQuery } from 'generated/schemas/getUsedPipelineTypes';
import { getPreparedFiltersModels } from 'lib/features/shopwindow/helpers';
import { Categories as CategoriesCommon } from '../../common/Categories';
import { getCategories } from '../../common/Categories/helpers';
import { CategoriesProps } from './types';

export const Categories: FC<CategoriesProps> = memo(({ rootName, ...props }) => {
  const [, { value }] = useField(rootName);
  const { data } = useGetUsedPipelineTypesQuery({ filter: getPreparedFiltersModels(value) });
  const list = useMemo(() => getCategories((data?.result || [])), [data]);
  return <CategoriesCommon {...props} list={list} />;
});