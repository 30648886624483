import { useMemo } from 'react';
import { Offer } from 'generated/types';
import { useLazyGetOffersQuery } from 'lib/features/offers';
import useLazyPagination, { ResultType, FetchResult } from 'hooks/useLazyPagination';

const useOffers = (prefetch?: ResultType<Offer> | null): FetchResult<Offer> => {
  const config = useMemo(() => {
    return { useLazyQuery: useLazyGetOffersQuery, prefetch };
  }, [prefetch]);
  return useLazyPagination<Offer>(config);
};

export default useOffers;
