import { forwardRef, memo } from 'react';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { PaperProps } from './types';
import classes from './Paper.module.scss';

export const Paper = memo(forwardRef<HTMLDivElement, PaperProps>(({
  className,
  children,
  testId,
  onClick,
}, ref) => {
  return (
    <Box
      ref={ref}
      className={cn(classes.content, className)}
      data-test-id={testId}
      direction="column"
      onClick={onClick}
    >
      {children}
    </Box>
  );
}));
