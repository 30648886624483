import { Item } from 'uikitv2/Dropdown/types';
import { Pages, Sorting } from 'lib/features/shopwindow/types';

export const getItems = (page?: Pages): Item[] => {
  switch (page) {
    case Pages.datasets:
    case Pages.models:
      return [
        { label: 'Newest', value: Sorting.newest },
      ];
    default:
      return [];
  }
};